<template>
  <div>
    <button class="more" v-on="$listeners">
      <img src="../assets/svg/refresh.svg">
    </button>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props:{

  }
}
</script>

<style scoped>
.more{
  /* Icon Button */


  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  gap: 10px;

  width: 28px;
  height: 28px;

  /* white */

  /* Shadows/Gray/1 Level */



  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>