<template>
  <div style="display: flex;height: 100%">
    <div class="resize" style="background-color: rgba(250, 248, 253, 1)">
      <img src="../../assets/svg/login.png" class="centerImage" @click="test()">
    </div>
    <div v-if="loginUser" class="resizeForm" style="background-color: #FFFFFF">
      <div class="imageMobile">
        <img src="../../assets/loginMobile.jpg" style="width: 100%;height: 95%">
      </div>
      <v-container>
        <v-col>
<!--          <span style="font-size: 22px">به شایان ترابر خوش آمدید!</span>-->
        </v-col>
        <v-col>
          <span style="font-size: 18px">ورود به حساب کاربری</span>
        </v-col>
        <v-col>
          <v-col>
            <span style="font-size: 14px;color: #6C7F96">نام کاربری </span>
          </v-col>
          <v-col>
            <input v-model="mobile" type="tel" class="input">
          </v-col>
          <v-col>
            <span style="font-size: 14px;color: #6C7F96;">کلمه عبور</span>
          </v-col>
          <v-col style="display: flex;flex-direction: column">
            <input class="input" v-model="password" type="password">
            <div style="margin-top: 5px">
<!--              <span style="font-size: 12px;color: #6C7F96;">کلمه عبور را فراموش کردید؟</span>-->
<!--              <span style="font-size: 12px;color: #396AFF" @click="loginUser = false;forget = true">بازگردانی کلمه عبور</span>-->
            </div>
          </v-col>
        </v-col>
        <v-col>
          <v-btn
          class="primary"
          style="border-radius: 23px;min-width: 95px!important;"
          :loading="loading"
          :disabled="loading"
          @click="login()"
          >
            <span>
              ورود
            </span>
          </v-btn>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios";
// import router from "@/router";
import Vue from "vue";
export default {
  name: "Login",
  data() {
    return {
      mobile: '',
      loginUser : true,
      forget : false,
      code : '',
      passwordConfirm : '',
      confirm: false,
      password: '',
      loading: false,
    }
  },
  methods: {
    forgetPassword(){
      if (this.password === this.passwordConfirm)
      {
        this.loading = true
        axiosApi().post('v1/token/forgetPassword',{
          'mobile' : this.mobile,
          'password' : this.password,
          'otp' : this.code
        }).then(()=>{
          Vue.$toast.open({
            message: 'پسورد با موفقیت تغییر یافت.',
            type: 'success',
          })
          this.confirm =false
          this.forget = false
          this.loginUser = true
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })
      }else {
        Vue.$toast.open({
          message: 'لطفا پسورد ها را با دقت بیشتر و یکسان وارد کنید',
          type: 'error',
        })
      }
    },
    sendSms(){
      this.loading = true
      axiosApi().post('v1/token/reSendCode',{
        'mobile': this.mobile
      }).then(()=>{
        this.loading = false
        this.forget = false
        this.confirm = true
        Vue.$toast.open({
          message: 'کد برای شما ارسال شد',
          type: 'success',
        })
      }).catch(()=>{
        this.loading = false
        this.forget = false
        this.confirm = true
      })
    },
    test(){
      Vue.$toast.open({
        message: 'خوش آمدید',
        type: 'success',
      })
    },
    login() {
      this.loading = true
      axiosApi().post('auth/login', {
        'username': this.mobile,
        'password': this.password
      }).then((result) => {
        Vue.$toast.open({
          message: 'خوش آمدید',
          type: 'success',
        })
        // localStorage.setItem('role', result.data.data.user.role)
        localStorage.setItem('token', result.data.data.user.access_token)
        this.$parent.login = true
        location.replace('/')
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.resize{
  width: 50%;
}
.resizeForm{
  width: 50%;
}
.centerImage {
  display: block;
  margin: auto;
  width: 65%;
  height: 95%;
}

.input {
  text-align: center;
  box-sizing: border-box;
  max-width: 350px;
  width: 100%;
  height: 49px;
  left: 232px;
  top: 320px;
  opacity: 0.5;
  border: 1px solid #9CB1CA;
  border-radius: 8px;
}
.imageMobile{
  display: none;
}
@media only screen and (max-width: 780px) {
  .resize{
    //display: none;
    width: 40%;
  }

  .resizeForm{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 600px) {
  .resize{
    display: none;
    width: 0;
  }
  .imageMobile{
      display: block;
      height: 200px;
  }

  .resizeForm{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>
