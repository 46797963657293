<template>
  <div v-click-outside="() => (isOpen = false)" style="position:relative;">
    <button
        type="button"
        class="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        aria-expanded="false"
        @click="toggle"
    >
      <span>{{ text }}</span>
      <slot name="icon"></slot>
    </button>
    <transition
        enter-class="-translate-y-1 opacity-0 "
        enter-to-class="translate-y-0 opacity-100 "
        enter-active-class="transition ease-out duration-50"
        leave-class="translate-y-0 opacity-100 "
        leave-to-class="-translate-y-1 opacity-0 "
        leave-active-class="transition duration-150 ease-in"
    >
      <template
          v-if="isOpen"

      >
        <slot />
      </template>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: "HeaderMenu",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    $route() {
      this.isOpen = false
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  }
}
</script>

<style scoped>

</style>