import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Auth/Login";
import Home from "@/views/Home/index"
// import HelloWorld from "@/components/HelloWorld";
// import Home from '../views/Home/index'

Vue.use(VueRouter)
let routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta : 'خانه'
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    meta : 'ورود به پنل  '
  },
  {
    path: '*',
    name: 'noPage',
    redirect: '/'
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  ,
  scrollBehavior()
  {
    // document.getElementById('satrex').scrollIntoView();
  }
})
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  if (to.name === 'login' && token != null) next({ name: 'Home' })
  else next()
  if (to.name !== 'login' && token == null) next({name : 'login'})
  else next()
  document.title = to.meta ;
  next ()
})
export default router
