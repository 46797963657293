<template>
  <div>
    <button class="more" v-on="$listeners">
      <span style="margin-bottom: 4px">...</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props:{

  }
}
</script>

<style scoped>
.more{
  /* Icon Button */


  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  gap: 10px;

  width: 28px;
  height: 28px;

  /* white */

  background: #FFFFFF;
  border: 1px solid #C4CED7;
  /* Shadows/Gray/1 Level */

  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>