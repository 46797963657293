<template>
  <div>
    <div>
      <div style="margin: 10px">
        <v-btn style="background-color: #294566!important;color: #FFFFFF;min-width: 21px!important;" @click="dialog = true">
          <span>ایجاد کاربر</span>
        </v-btn>
      </div>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
      >
        <template v-slot:item.operations="{ item }">
          <v-menu
              transition="slide-x-transition"
              bottom
              right
              origin="center center"
          >
            <template v-slot:activator="{ on, attrs }">
              <MoreBtn
                  v-bind="attrs"
                  v-on="on"
              ></MoreBtn>
            </template>
            <v-list>
              <v-list-item
                  link
              >
                <v-list-item-title>
                  <span style="font-size: 12px" @click="show(item.id,1)">شارژ ۱ روزه</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
              >
                <v-list-item-title>
                  <span style="font-size: 12px" @click="show(item.id,2)">شارژ ۱ ماه</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
              >
                <v-list-item-title>
                  <span style="font-size: 12px" @click="show(item.id,3)">شارژ ۲ ماه</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
              >
                <v-list-item-title>
                  <span style="font-size: 12px" @click="show(item.id,4)">شارژ ۳ ماه</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <v-dialog
        max-width="472"
        v-model="dialogConfirm"
    >
      <v-card>
        <v-toolbar  dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            <span style="color: #294566">شارژ کاربر</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
            class="pa-4 black--text"
        >
          <span style="font-size: 14px ; color: #6C7F96">
           آیا میخواهید کاربر شارژ شود؟؟
          </span>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-btn
              style="background-color: #F0F3F8!important;min-width: 48px!important;"
              @click="dialogConfirm = !dialogConfirm"
          >
            <span>لغو</span>
          </v-btn
          >
          <v-btn
              color="primary"
              style="min-width: 65px !important;"
              @click="buy(account_id,package_id)"
          >
            <span>
              تایید
            </span>
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        max-width="472"
        v-model="dialog"
    >
      <v-card>
        <v-toolbar  dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            <span style="color: #294566">ایجاد کاربر</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
            class="pa-4 black--text"
        >
          <div>
            <v-autocomplete
                :items="types"
                v-model="type"
                outlined
                color="white"
                item-value="name"
                item-text="title"
                label=" نوع را انتخاب کنید"
            ></v-autocomplete>
          </div>
          <div class="spaceBetween">
            <v-col
                cols="12"
                sm="6"
                md="5"
            >
              <span style="font-size: 14px;color: #6C7F96">نام کاربری </span>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="5"
            >
              <span style="font-size: 14px;color: #6C7F96">رمز </span>
            </v-col>
          </div>
          <div class="spaceBetween">
            <v-col
                cols="12"
                sm="6"
                md="5"
            >
              <v-text-field
                  v-model="username"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="5"
                md="5"
            >
              <v-text-field
                  v-model="password"
                  outlined
              ></v-text-field>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-btn
              style="background-color: #F0F3F8!important;min-width: 48px!important;"
              @click="dialog = !dialog"
          >
            <span>لغو</span>
          </v-btn
          >
          <v-btn
              color="primary"
              style="min-width: 65px !important;"
              @click="store()"
          >
            <span>
              ایجاد کاربر
            </span>
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios";
import {convertDateOnly} from "@/plugins/getPersianDate";
import Vue from "vue";
export default {
  name: "index",
  components: {},
  watch: {
    dates() {
      this.translateDate()
    }
  },
  data() {
    return {
      username : '' ,
      password : '' ,
      types : [
        {name : 'openconnect', title : 'openconnect'},
        {name : 'v2', title : 'v2'}
      ] ,
      type : '' ,
      search : '' ,
      dialog : false,
      dialogConfirm : false,
      account_id : '',
      package_id : '',
      headers : [
        { text: 'نام کاربری', value: 'username' },
        { text: 'پسورد', value: 'password' },
        { text: 'وضعیت', value: 'status' },
        { text: 'نوع', value: 'type' },
        { text: 'ایجاد', value: 'created_at.jalali' },
        { text: 'انقضا', value: 'expired_at.jalali' },
        { text: 'عملیات', value: 'operations' },
      ],
      users: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      axiosApi().get('/account')
          .then((result)=>{
            this.users = result.data.data.accounts.data
            console.log(this.users)
      })
    },
    translateDate() {
      this.show = true
      for (let i = 0; i < 7; i++) {
        this.dates[i] = this.getPersian(this.dates[i])
      }
    },
    getPersian(date) {
      return convertDateOnly(date)
    },
    show(account_id,package_id){
      this.account_id = account_id
      this.package_id = package_id
      this.dialogConfirm = true
    },
    buy(account_id,package_id){
      axiosApi().post('account/buy',{
        account_id : account_id,
        package_id : package_id
      }).then(()=>{
        Vue.$toast.open({
          message: 'شارژ با موفقیت انجام شد',
          type: 'success',
        })
        this.dialogConfirm = false
        this.getUsers()
      })
    },
    store(){
      axiosApi().post('/account/create',{
        type : this.type,
        username : this.username,
        password : this.password,
        status : 'active'
      }).then(()=>{
        Vue.$toast.open({
          message: 'کاربر با موفقیت انجام شد',
          type: 'success',
        })
        this.getUsers()
        this.dialog = false
      }).catch(()=>{
        Vue.$toast.open({
          message: 'در ایجاد کاربر مشکلی پیش آمده است',
          type: 'error',
        })
      })
    },
  }
}
</script>

<style scoped>
.blue {
  margin-top: 7px;
  margin-left: 7px;
  width: 12px;
  height: 12px;
  left: 290px;
  border-radius: 128px;
}

.reportLine {
  margin: 10px;
}

.flex {
  display: flex;
}
.trend{
  height: 100%;
}
@media screen and (max-width: 600px) {
  .flex {
    display: unset;
  }

  .trend {
    padding-top: 40px;
  }
}
</style>
