<template>
  <div>
    <div v-if="login">
      <v-app>
        <div style="display: flex;flex-direction: column;overflow: auto;height:100vh">
          <v-app-bar
              app
              class="appBar"
              color="#0B1144"
              style="height: 70px;left: unset;transition: all 0.3s ease 0s;"
              :style="isOpen ? 'width: 250px;' : 'width: 57px;'"
          >
            <div class="d-flex align-center">
              <img src="./assets/svg/menu.svg" @click="toggleSidebar()"
                   :style="isOpen ? 'width: 18px;height: 13px;margin: 33px 25px 33px 33px' : ''"/>
              <img v-if="isOpen" src="./assets/svg/shayan.png">
            </div>
            <v-spacer></v-spacer>
          </v-app-bar>
          <div style="display: flex">
            <div :class="isOpen ? 'open' : 'close'"></div>
            <div id="main" style="height: fit-content;flex:1;padding:20px;overflow:auto">
              <v-row id="header-menu" class="headerMenu">
                <v-col sm="6">
                  <span style="font-size: 14px;font-family : IRANSans" id="roz">امروز: </span>
                  <span style="font-size: 14px;font-family : IRANSans">{{ persianDate + ' ' }}</span>
                  <span style="font-size: 14px;font-family : IRANSans" class="time">ساعت:</span>
                  <span style="font-size: 14px;font-family : IRANSans">{{ timestamp }}</span>
                </v-col>
                <v-col sm="3" v-if="role !== 'ADMIN'">
<!--                  <span>شارژ شما : {{ wallet }}</span>-->
                </v-col>
                <v-col id="name" style="display: flex;justify-content: space-around">
                  <div style="display: flex">
                    <div>
                      <img src="./assets/svg/admin.png" style="width: 40px;height: 35px;margin-top: 7px"/>
                    </div>
                    <div>
                      <div>
                        <span style="font-size: 12px">{{this.name}}</span>
                      </div>
                      <div>
                        <span style="font-size: 10px;color: #9CB1CA">
                          {{ getRoleName() }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="margin-left: 10px">
                      <header-menu>
                        <template #icon>
                          <v-badge
                              :content="notificationCount"
                              :value="notificationCount"
                              offset-x="25px"
                              offset-y="25px"
                              color="rgba(231, 2, 82, 0.1)"
                              overlap
                          >
                            <img src="./assets/svg/notification.svg" style="width: 16px;margin-top: 15px"/>
                          </v-badge>
                        </template>
                        <v-card style="width: 279px;position: absolute;left: 0;top: 100%;max-width: unset">
                          <v-container>
                            <v-row>
                              <v-col>
                                <span>اعلان ها</span>
                              </v-col>
                              <v-col style="display: flex">
                                <v-spacer></v-spacer>
                                <span style="color: #E70252;border-block-width: 1px;border-bottom-style: solid;"
                                      @click="notificationCount = 0 , notification= []">پاک کردن</span>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-divider></v-divider>
                          <div v-for="(item,index) in notification" v-bind:key="index">
                            <v-list>
                              <v-list-item>
                                <v-list-item-title>
                                  <router-link style="background-color: white" :to="item.route">
                                    <span style="color: #1f1f1f">
                                      {{ item.message }}
                                    </span>
                                  </router-link>
                                </v-list-item-title>
                                <v-list-item-action>
                                  <!--                                    <router-link  style="background-color: white!important;"  :to="item.route" >-->
                                  <span style="color: black" @click="notifications(item.route)">{{ getTime() }}</span>
                                  <!--                                    </router-link>-->
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-card>
                      </header-menu>
                    </div>
                    <div>
                      <img src="./assets/svg/settings.svg" style="width: 20px;margin-top: 15px"/>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div id="header-menu-mobile" class="headerMobile">
                <div style="padding: 30px">
                  <img src="./assets/svg/menu.svg" @click="toggleSidebar()" style="position: absolute;z-index: 1000"/>
                </div>
                <div style="padding: 14px 20px 20px 20px">
                  <header-menu>
                    <template #icon>
                      <v-badge
                          :content="notificationCount"
                          :value="notificationCount"
                          offset-x="25px"
                          offset-y="25px"
                          color="rgba(231, 2, 82, 0.1)"
                          overlap
                      >
                        <img src="./assets/svg/notification.svg" style="width: 16px;margin-top: 15px"/>
                      </v-badge>
                    </template>
                    <v-card style="width: 279px;position: absolute;left: 0;top: 100%;max-width: unset;z-index: 1000">
                      <v-container>
                        <v-row>
                          <v-col>
                            <span>اعلان ها</span>
                          </v-col>
                          <v-col style="display: flex">
                            <v-spacer></v-spacer>
                            <span style="color: #E70252;border-block-width: 1px;border-bottom-style: solid;"
                                  @click="notificationCount = 0 , notification= []">پاک کردن</span>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-divider></v-divider>
                      <div v-for="(item,index) in notification" v-bind:key="index">
                        <v-list>
                          <v-list-item>
                            <v-list-item-title>
                              <router-link style="background-color: white" :to="item.route">
                                    <span style="color: #1f1f1f">
                                      {{ item.message }}
                                    </span>
                              </router-link>
                            </v-list-item-title>
                            <v-list-item-action>
                              <!--                                    <router-link  style="background-color: white!important;"  :to="item.route" >-->
                              <span style="color: black" @click="notifications(item.route)">{{ getTime() }}</span>
                              <!--                                    </router-link>-->
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-card>
                  </header-menu>
                </div>
              </div>
              <div class="minHeight" style="height: 70px"></div>
              <router-view/>
            </div>

          </div>
        </div>
      </v-app>
      <Sidebar></Sidebar>
    </div>
    <div v-if="!login">
      <v-app>
        <router-view/>
      </v-app>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import "./assets/font/flaticon.css";
import "./assets/styles/main.scss";
import moment from "jalali-moment";
import axiosApi from "@/plugins/axios";
import HeaderMenu from "@/components/HeaderMenu";

export default {
  name: 'App',
  components: {
    HeaderMenu,
    Sidebar
  },
  data() {
    return {
      page: '',
      isOpen: true,
      isHide: true,
      login: true,
      menu: '',
      name : '',
      role: '',
      socket: false,
      dataSocket: [],
      notificationCount: 0,
      factoryId: 'factory',
      notification: [],
      timestamp: '',
      persianDate: '',
      companyId: 'company'
    }
  },
  mounted() {
    this.role = localStorage.getItem('role')
    this.toggleSidebar()
    this.getRole()
    if (localStorage.getItem('token') === null)
      this.login = false
    if (localStorage.getItem('role') === 'undefined')
    {
      sessionStorage.clear();
      localStorage.clear();
      location.replace('/')
    }
    setInterval(this.getNow, 1000);
    // window.Echo.channel(this.dataSocket[0])
    //     .listen(this.dataSocket[1], (e) => {
    //       if (e.message)
    //       {
    //         this.notification.push({message : e.message , route : e.route})
    //         this.notificationCount += 1
    //         console.log(e.route)
    //       }
    //       if (e.wallet)
    //       {
    //         this.wallet = e.wallet
    //       }
    //     })

  },
  methods: {
    getTime() {
      let time = new Date();
      return time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds()
    },
    notifications(route) {
      this.notification = this.notification.filter((item) => {
        return item.route !== route
      })
      this.notificationCount = this.notification.length
    },
    getNow() {
      const today = new Date();
      const time = today.getHours() + ":" + today.getMinutes();
      const dateTime = time;
      this.timestamp = dateTime;
      const persianD = moment();
      this.persianDate = persianD.locale('fa').format('jD jMMMM jYYYY');
    },
    toggleSidebar() {
      document.getElementById("sidebar").classList.toggle('active');
      this.isOpen = !this.isOpen
      this.isHide = !this.isHide
    },
    getRoleName() {
      // return localStorage.getItem('role') === 'ADMIN' ? 'ادمین' : localStorage.getItem('role') === 'COMPANY' ? 'شرکت' : localStorage.getItem('role') === 'FACTORY' ? 'واحد صنفی' : 'کاربر شرکت'
    },
    getRole() {
      switch (this.role) {
        case "ADMIN": {
          window.Echo.channel('admin')
              .listen('.App\\Events\\Admin', (e) => {
                if (e.message) {
                  this.notification.push({message: e.message, route: e.route})
                  this.notificationCount += 1
                  console.log(e.route)
                }
                if (e.wallet) {
                  this.wallet = e.wallet
                }
              })
          return;
        }
        case "COMPANY" : {
          axiosApi().get('v1/company/dashboard')
              .then((result) => {
                this.wallet = result.data.data.wallet
                this.companyId += result.data.data.companyId
                this.name = result.data.data.companyName
              })
          return ;
        }
        case "FACTORY" : {
          axiosApi().get('v1/factory/dashboard')
              .then((result) => {
                this.wallet = result.data.data.wallet
                this.factoryId += result.data.data.factoryId
                this.name = result.data.data.factoryName
              })
          return ;
        }
        case "COMPANY_MEMBER" : {
          axiosApi().get('v1/company/dashboard')
              .then((result) => {
                this.wallet = result.data.data.wallet
                this.companyId += result.data.data.companyId
                this.name = result.data.data.companyName
              })
        }
      }
    }
  }

};
</script>
<style>
.headerMobile{
  z-index: 100;
  display: none;
  background-color: #0B1144;
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: space-between;

}
.headerMenu{
  position: fixed;
  width: -webkit-fill-available;
  z-index: 10;
  background-color: #f7f8fa
}
span {
  font-size: 14px;
  font-family: IRANSansWeb(FaNum);
}

.theme--light.v-application {
  background: #F7F8FA !important;
}

.v-application .success {
  background-color: #02b5a0 !important
}

@media only screen and (max-width: 960px) {
  .widthOpen {
    margin-right: 72px !important;
  }
}

@media screen and (max-width: 600px) {
  .appBar  {
    display: none;
  }
  .close{
      width: unset!important;
  }
  .open{
    width: unset!important;
  }
  #sidebar.active {
    width: 0;
  }
  #header-menu {
    display: none;
  }
  .headerMobile{
    display: flex;
  }
}

@media only screen and (max-width: 500px) {

  #search, #name, #roz {
    content: none;
  }

}

.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: #130f40;
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
  opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
  background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.8);
}

.burger-bar--3 {
  transform: translateY(6px);
}

.close {
  height: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.3s;
  width: 57px;
}

.open {
  /*height: 46px;*/
  width: 250px;
  transition: 0.3s;
  background: #dbe0ea;
}

.v-badge__badge {
  color: #E70252;
}

/*html{*/
/*zoom: 0.8;*/
/*}*/
</style>
