<template>
  <div>
    <v-col id="sidebar" @mouseover="$parent.isOpen = true || $parent.isHide === true"
           @mouseleave="$parent.isOpen = false || $parent.isHide === true"
           style="background-blend-mode: color-burn">
      <div>
        <ul style="text-align: right;">
          <router-link tag="li" :to="{name : 'Home'}">
            <div class="sidebarItemList">
              <span v-if="$parent.isOpen">داشبورد</span>
              <img src="../assets/svg/dashboard.svg"/>
            </div>
          </router-link>
<!--          <router-link tag="li" :to="{name : 'Home'}">-->
<!--            <div class="sidebarItemList">-->
<!--              <span v-if="$parent.isOpen">داشبورد</span>-->
<!--              <img src="../assets/svg/dashboard.svg"/>-->
<!--            </div>-->
<!--          </router-link>-->
<!--          <router-link tag="li" :to="{name : 'Home'}">-->
<!--            <div class="sidebarItemList">-->
<!--              <span v-if="$parent.isOpen">داشبورد</span>-->
<!--              <img src="../assets/svg/dashboard.svg"/>-->
<!--            </div>-->
<!--          </router-link>-->
          <div class="sidebarItemList">
            <li @click="LoginOut()">
              <span v-if="$parent.isOpen">خروج</span>
              <img src="../assets/svg/exit.svg" style="width: 15px;margin-top: 15px"/>
            </li>
          </div>

        </ul>
      </div>
    </v-col>
  </div>
</template>

<script>
// import axiosApi from "@/axios";

export default {
  name: "Sidebar.vue",

  data() {
    return {
      Admin: [
        {
          name: 'داشبورد',
          route: '/',
          subSidebar: null,
          svgAddress: '../assets/svg/dashboard.svg',
        },
        {
          name: 'حواله ها',
          route: '',
          variable: 'remittance',
          subSidebar: [{
            name: '',
            route: '',
            svgAddress: '',
          }],
          svgAddress: '../assets/svg/remittances.svg',
        },
      ],
      companies: false,
      supports: false,
      receipt: false,
      factory: false,
      role: '',
      users: false,
      orders: false,
      remittance: false
    }
  },
  mounted() {
    this.getRole()
  },
  methods: {
    getRole() {
      this.role = localStorage.getItem('role')
    },
    LoginOut() {
      sessionStorage.clear();
      localStorage.clear();
      location.replace('/')
    }
  },
  watch: {
    companies() {
      if (this.companies) {
        this.supports = false
        this.remittance = false
        this.factory = false
      }
    },
    supports() {
      if (this.supports) {
        this.companies = false
        this.remittance = false
        this.factory = false
      }
    },
    remittance() {
      if (this.remittance) {
        this.supports = false
        this.companies = false
        this.factory = false
      }
    },
    factory() {
      if (this.factory) {
        this.companies = false
        this.remittance = false
        this.supports = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

img {
  width: 15px;
  margin-top: 15px
}

.close {
  height: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.3s;
}

.sidebarItemList {
  margin-right: 11px;
}

.open {
  width: 250px;
  height: 46px;
  transition: 0.3s;
  background: #040930;
}

.openFinance {
  height: 160px;
  transition: 0.3s;
  background: #dbe0ea;
}

.iconRotate {
  transform: rotate(90deg);
}

.sidebar-panel {
  &.is-generic {
    .subpanel-header {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px;

      .project-dropdown {
        margin-right: 12px;

        [dir='rtl'] & {
          margin-left: 12px;
          margin-right: 0;
        }

        cursor: pointer !important;

        > img {
          height: 32px;
          width: 32px;
          border-radius: var(--radius-rounded);
        }

        .dropdown-menu {
          margin-top: 12px;

          .dropdown-content {
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;

            .dropdown-block {
              display: flex;
              align-items: center;
              padding: 16px;

              &:hover {
                background: var(--fade-grey-light-4);
              }

              .meta {
                margin-left: 12px;

                [dir='rtl'] & {
                  margin-left: 0;
                  margin-right: 12px;
                }

                font-family: var(--font);

                span {
                  display: block;

                  &:first-child {
                    font-size: 0.95rem;
                    font-weight: 500;
                    color: var(--dark-text);
                    line-height: 1.2;
                    max-width: 140px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }

                  &:nth-child(2) {
                    //text-transform: uppercase;
                    color: var(--light-text);
                    font-size: 0.85rem;
                  }
                }
              }
            }
          }
        }
      }

      h3 {
        font-family: var(--font-alt);
        color: var(--light-text);
        font-size: 1.3rem;
      }

      .panel-close {
        margin-left: auto;

        [dir='rtl'] & {
          margin-left: 0;
          margin-right: auto;
        }

        display: block;
        width: 18px;
        height: 18px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .inner {
      ul {
        padding: 10px 0;
      }

      li {
        height: 36px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-left: 2px solid transparent;

        [dir='rtl'] & {
          border-left: none;
          border-right: 2px solid transparent;
        }

        cursor: pointer;

        &.is-active {
          a {
            font-weight: 500;
            color: var(--primary);
          }

          > a {
            font-weight: 600;
          }
        }

        > a.router-link-exact-active {
          font-weight: 600;
          color: var(--primary);
        }

        &.has-children {
          .is-submenu.router-link-exact-active {
            font-weight: 500;
            color: var(--primary);
          }
        }

        &.divider {
          cursor: default;
          pointer-events: none;
          height: 10px;
          margin: 5px 20px 10px 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);

          &.with-label {
            border-bottom: 0;
            margin-top: 25px;

            &::after,
            &::before {
              flex: 0;
            }

            .divider-label {
              // padding: 1rem;
              font-size: 0.7rem;
            }
          }
        }

        &.has-children {
          display: block;
          height: unset;
          min-height: 36px;
          margin-bottom: 4px;

          &.active {
            ul {
              max-height: 500px;
            }

            .collapse-wrap > a {
              color: var(--dark-text);
              font-weight: 600;

              svg {
                transform: rotate(90deg) !important;
              }
            }
          }

          &:hover {
            .collapse-wrap > a {
              color: var(--dark-text);
            }
          }

          &.is-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tag {
              margin-left: auto;
              margin-right: 20px;

              [dir='rtl'] & {
                margin-left: 20px;
                margin-right: auto;
              }

              border-radius: 100px;
              background: var(--danger);
              color: var(--white);
              font-size: 0.8rem;
              font-weight: 600;
            }
          }

          .collapse-wrap {
            display: flex;
            align-items: stretch;
            height: 100%;
            min-height: 36px;

            > a {
              font-family: var(--font-alt);
              display: flex;
              align-items: center;
              font-size: 0.9rem;
              font-weight: 500;
              color: var(--light-text);

              svg {
                position: relative;
                top: 1px;
                height: 18px;
                width: 18px;
                margin-left: auto;

                [dir='rtl'] & {
                  margin-left: unset;
                  margin-right: auto;
                }

                transform: rotate(0);
                color: var(--light-text);
                transition: all 0.3s;
              }
            }
          }

          ul {
            padding: 0;
            transition: max-height 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
            max-height: 0;
            overflow: hidden;

            li {
              height: 32px;
              font-family: var(--font);

              &.is-active,
              .router-link-exact-active {
                .is-submenu {
                  font-weight: 500;

                  svg {
                    display: block;
                  }
                }
              }

              .is-submenu {
                display: flex;
                font-weight: 400;
                font-family: var(--font);
                align-items: center;
                padding-left: 32px;

                [dir='rtl'] & {
                  padding-left: unset;
                  padding-right: 32px;
                }

                font-size: 0.9rem;

                &.is-active,
                .router-link-exact-active {
                  font-weight: 500;
                  color: var(--primary);
                }

                .lnil,
                .lnir {
                  font-size: 1.2rem;
                  margin-right: 10px;

                  [dir='rtl'] & {
                    margin-left: 10px;
                    margin-right: 0;
                  }
                }

                > span {
                  font-family: var(--font);
                }

                svg {
                  display: none;
                  height: 8px;
                  width: 8px;
                  max-width: 8px;
                  min-width: 8px;
                  stroke-width: 2px;
                  margin-right: 8px;

                  [dir='rtl'] & {
                    margin-left: 8px;
                    margin-right: unset;
                  }

                  &.is-auto {
                    margin-left: auto;

                    [dir='rtl'] & {
                      margin-left: unset;
                      margin-right: auto;
                    }

                    height: 15px;
                    width: 15px;
                    max-width: 15px;
                    min-width: 15px;
                    stroke-width: 2px;
                    margin-right: 4px;

                    [dir='rtl'] & {
                      margin-left: 4px;
                      margin-right: unset;
                    }
                  }
                }
              }
            }
          }
        }

        a {
          font-family: var(--font);
          display: block;
          width: 100%;
          padding: 0 20px;
          font-size: 0.95rem;
          color: var(--light-text);

          &:hover {
            color: var(--dark-text);
          }

          .tag {
            line-height: 1.8;
            height: 1.8em;
            font-size: 0.7rem;
            margin-left: 0.25rem;

            [dir='rtl'] & {
              margin-left: 0;
              margin-right: 0.25rem;
            }
          }
        }

        > a {
          font-family: var(--font-alt);
          font-size: 0.9rem;
          font-weight: 500;
          color: var(--light-text);
        }
      }

      .docked-action {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 64px;
        width: 100%;
        padding: 0 26px 0 16px;

        [dir='rtl'] & {
          padding: 0 16px 0 26px;
        }

        margin: 0;

        &.has-search {
          bottom: 160px;
        }

        &.action-wrap {
          .button {
            height: 36px !important;

            svg {
              height: 16px;
              width: 16px;
              margin-right: 2px;
              margin-left: -2px;

              [dir='rtl'] & {
                margin-left: 2px;
                margin-right: -2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
