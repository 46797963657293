import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import MoreBtn from "@/components/MoreBtn";
import VueExcelXlsx from "vue-excel-xlsx";
import RefreshBtn from "@/components/Refresh"
import VueApexCharts from 'vue-apexcharts'
import Trend from "vuetrend"

Vue.use(Trend)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('MoreBtn',MoreBtn)
Vue.component('RefreshBtn',RefreshBtn)
window.Pusher = require('pusher-js');


Vue.use(VueExcelXlsx);



Vue.config.productionTip = false
Vue.use(VueToast,{
  duration : 5000,
  position: 'bottom-left',
})
Vue.use(VuePersianDatetimePicker, {
  name: 'date-picker',
  props: {
    inputFormat: 'YYYY-MM-DD',
    format: 'jYYYY-jMM-jDD',
    editable: false,
    inputClass: 'datePicker',
    placeholder: 'لطفا تاریخ را انتخاب کنید',
    altFormat: 'YYYY-MM-DD',
    autoSubmit: false,

    //...
    //... And whatever you want to set as default
    //...
  }
});
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
