import axios from "axios";
import axiosRetry from "axios-retry";
// import router from "@/router";
import Vue from "vue";
const httpRequest =  axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'http://azizan.me/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const defaultApi = ()  => {
  httpRequest.defaults.headers.common["Authorization"] = null;
  return httpRequest;
};

const axiosApi = ()=>{

  let token = null;
  // if (Store.state.token) {
  //   token = Store.state.token;
  // } else {
    token = localStorage.getItem("token");
  // }
   httpRequest.defaults.headers.common["Authorization"] = 'Bearer '+ token;


  return httpRequest
}


axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error.response.status === 401 || !window.navigator.onLine;
  },
});
// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });
//
//   failedQueue = [];
// };

// let isRefreshing = false;
// let failedQueue = [];
//
 axiosApi().interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;

      if (!window.navigator.onLine) {
        originalRequest._retry = true;
        setTimeout(() => {
          axiosApi().originalRequest;
        }, 10000);
      }

      if (error.response.status === 400) {
        if (error.response.data.error)
        {
          Vue.$toast.open({
            message: error.response.data.error,
            type: 'error',
          })
        }
        let errors = error.response.data.errors
        for (const [, value] of Object.entries(errors)) {
          Vue.$toast.open({
            message: value[0],
            type: 'error',
          })
        }
      }
      if (error.response.status === 403) {
        if (error.response.data.message)
        {
          Vue.$toast.open({
            message: error.response.data.message,
            type: 'error',
          })
        }
        let errors = error.response.data.errors
        for (const [, value] of Object.entries(errors)) {
          Vue.$toast.open({
            message: value[0],
            type: 'error',
          })
        }
      }
      if (error.response.status === 401 ) {
        // console.log(isRefreshing,failedQueue)
        localStorage.clear()
        location.replace('/auth/login')
        // router.push('/auth/login')
        // if (isRefreshing) {
        //   return new Promise(function (resolve, reject) {
        //     failedQueue.push({resolve, reject});
        //   })
        //       .then((token) => {
        //         originalRequest.headers["Authorization"] = "Bearer " + token;
        //         return axios(originalRequest);
        //       })
        //       .catch((err) => {
        //         return Promise.reject(err);
        //       });
        // }
        //
        // isRefreshing = true;
        // originalRequest._retry = true;

        // return new Promise(function (resolve, reject) {
        //   defaultApi()
        //       .post("api/Manage/v1/Token/Refresh", {
        //         refreshToken: Store.state.refreshToken,
        //       })
        //       .then((res) => {
        //         if (res.status === 201 || res.status === 200) {
        //           localStorage.removeItem("refreshToken");
        //           if (!res.data.isSuccess) {
        //             processQueue(error, null);
        //             console.log("شما به دلیل خطا در رفرش توکن به بیرون هدایت شدید");
        //             localStorage.removeItem('token')
        //             localStorage.removeItem('refreshToken')
        //             window.location.replace("/login.html")
        //             reject();
        //           } else {
        //             axiosApi().defaults.headers.common["Authorization"] =
        //                 "Bearer " + res.data.data.jwtToken.tokenValue;
        //             localStorage.setItem('token', res.data.data.jwtToken.tokenValue)
        //             localStorage.setItem('refreshToken', res.data.data.refreshToken.tokenValue)
        //             Store.commit('setToken', res.data.data.jwtToken.tokenValue)
        //             Store.commit('setRefreshToken', res.data.data.refreshToken.tokenValue)
        //             let add_minutes = function (dt, minutes) {
        //               return new Date(dt.getTime() + minutes * 60000);
        //             }
        //             let todayDate = add_minutes(new Date(), 51).toISOString().slice(0, 10);
        //             let date = add_minutes(new Date(), 51)
        //             let hours = date.getHours();
        //             let min = date.getMinutes();
        //             let dateTime = todayDate + ' ' + hours + ':' + min;
        //             localStorage.setItem('expiration', dateTime)
        //             originalRequest.headers.Authorization =
        //                 "bearer " + res.data.data.jwtToken.tokenValue;
        //             processQueue(null, res.data.data.jwtToken.tokenValue);
        //             resolve(axios(originalRequest));
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //
        //         console.log(err)
        //         localStorage.removeItem('token')
        //         localStorage.removeItem('refreshToken')
        //         window.location.replace("/login.html")
        //       })
        //       .then(() => {
        //         isRefreshing = false;
        //       });
        // });
      }
      return Promise.reject(error);
    }
);
// axiosApi.interceptors.response.use((response) => {
//         return response
//     },
//     function (error) {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             axiosApi().post('api/Manage/v1/Token/Refresh',
//                 {
//                     refreshToken : store.state.refreshToken
//                 })
//                 .then(({data}) => {
//                     localStorage.setItem('token', data.data.jwtToken.tokenValue)
//                     localStorage.setItem('refreshToken', data.data.refreshToken.tokenValue)
//                     this.$store.commit('setToken',data.data.jwtToken.tokenValue)
//                     this.$store.commit('setRefreshToken',data.data.refreshToken.tokenValue)
//                     let add_minutes =  function (dt, minutes) {
//                         return new Date(dt.getTime() + minutes*60000);
//                     }
//                     let todayDate = add_minutes(new Date(), 51).toISOString().slice(0, 10);
//                     let date = add_minutes(new Date(), 51)
//                     // let now =  new Date();
//                     let hours = date.getHours();
//                     let min = date.getMinutes();
//                     let dateTime = todayDate + ' ' + hours + ':' + min;
//                     localStorage.setItem('expiration',dateTime)
//                     return axiosApi()(originalRequest)
//
//                 }).catch(({err}) => {
//                 console.log(err)
//                 localStorage.removeItem('token')
//                 localStorage.removeItem('refreshToken')
//                 window.location.replace("/login.html")
//             })
//         }
//     });
export default axiosApi;
